import React, { useState, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import calcStyles from "./calc.module.css";
import DateContext from "./DateContext";
import {
    parseISO,
    getHours,
    set,
    getDate,
    subHours,
    getMinutes,
    addMinutes,
    isWithinInterval,
    isToday,
    isAfter,
} from "date-fns";
import getWorkingHours from "../utils/getWorkingHours";
import isBefore from "date-fns/isBefore";

const DateSelector = (props) => {
    const { spot, dateError, dayType, reservations } = props;

    const spotMinTime = getWorkingHours(spot, dayType)[0];
    const spotMaxTime = subHours(getWorkingHours(spot, dayType)[1], 1);

    const countedMinTime = (currentDate, spotMinTime, maxTime, excludedTimes) => {
        if (!isToday(currentDate)) return spotMinTime;

        if (excludedTimes) {
            if (
                isAfter(currentDate, excludedTimes[0]) &&
                isBefore(currentDate, excludedTimes[excludedTimes.length - 1])
            ) {
                setCurrentDate(addMinutes(excludedTimes[excludedTimes.length - 1], 5));
            }
        }

        if (Object.keys(reservations).length !== 0 && reservations.constructor === Object) {
            const reservationsDates = Object.keys(reservations);
            const reservedTimesStart = parseISO(reservationsDates[0]);
            const reservedTimesEnd = parseISO(reservationsDates[reservationsDates.length - 1]);
            if (currentDate.length && reservedTimesStart.length && reservedTimesEnd.length) {
                if (isWithinInterval(currentDate, reservedTimesStart, reservedTimesEnd)) {
                    setCurrentDate(addMinutes(reservedTimesEnd, 5));
                    return addMinutes(reservedTimesEnd, 5);
                }
            }
        }

        if (getHours(currentDate) <= getHours(spotMinTime) || getDate(currentDate) > getDate(new Date())) {
            return set(new Date(), {
                hours: getHours(spotMinTime),
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            });
        }

        if (getHours(currentDate) >= getHours(maxTime) - 1) {
            return set(new Date(), {
                date: getDate(currentDate) + 1,
                hours: getHours(spotMinTime),
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            });
        }

        if (isToday(currentDate)) {
            return set(new Date(), {
                hours: getHours(new Date()) + 1,
            });
        }

        if (getHours(new Date()) + 1 === getHours(currentDate)) {
            return currentDate;
        }
        return set(currentDate, {
            hours: getHours(currentDate) + 1,
            minutes: Math.floor(getMinutes(currentDate) / 5) * 5,
        });
    };

    const countedMinDate = (date) => {
        if (getHours(date) >= getHours(spotMaxTime) - 1) {
            return set(date, {
                date: getDate(date) + 1,
                hours: getHours(spotMinTime),
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
            });
        } else {
            return new Date();
        }
    };

    const [currentDate, setCurrentDate] = useState(countedMinTime(new Date(), spotMinTime, spotMaxTime));

    const handleDateChange = (date = new Date()) => {
        fetch("https://isdayoff.ru/" + date.toISOString().slice(0, 10).replace(/-/g, ""))
            .then((res) => res.json())
            .then((data) => {
                data === 1 ? dateApi.setDayType("WEEKEND") : dateApi.setDayType("WEEKDAY");
            });

        setCurrentDate(date);
        dateApi.setDate(date);
    };

    registerLocale("ru", ru);

    const dateApi = useContext(DateContext);

    return (
        <div className={calcStyles.fieldGroup + " " + calcStyles.calendar}>
            <label className={calcStyles.fieldLabel} htmlFor="date">
                Дата
            </label>

            <DatePicker
                selected={currentDate}
                onChange={(currentDate) => {
                    handleDateChange(currentDate);
                }}
                locale={ru}
                minDate={countedMinDate(new Date())}
                dateFormat="dd.MM.yyyy"
                className={calcStyles.fieldInput}
                name="date"
            />
            {dateError && <div className={calcStyles.fieldError}>Необходимо выбрать дату и время игры</div>}
        </div>
    );
};
export default DateSelector;
