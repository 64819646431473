import React from "react";
import ButtonStyles from "./Button.module.css";

const Button = React.memo(props => {
    const { action, text, isDisabled } = props;
    return (
        <button
            className={ButtonStyles.Button}
            action={action}
            disabled={isDisabled}
        >
            {text}
        </button>
    );
});

export default Button;
