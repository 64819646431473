import React, { memo } from "react";
import calcStyles from "./calc.module.css";

const TotalPrice = props => {
    const { totalPrice } = props;

    return (
        <div className={calcStyles.fieldGroup}>
            <div className={calcStyles.fieldLabel}>Итоговая стоимость</div>
            <div className={calcStyles.totalPrice}>
                {totalPrice && totalPrice + "₽"}
            </div>
        </div>
    );
};

export default memo(TotalPrice);
