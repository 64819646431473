import React, { memo, useEffect } from "react";
import calcStyles from "./calc.module.css";
import { addMinutes, parseISO, closestTo, isBefore, isAfter, max } from "date-fns";

const PlayerSelector = (props) => {
    const { spot, setPlayers, players, playersError, reservations, date, playtime } = props;
    const playersCollection = [...new Set(spot.tariffs.map((tariff) => tariff.players))];

    const adjustedPlayers = Number(playersCollection.indexOf(Number(players)) < 0 ? playersCollection[0] : players);

    const lastReservedTime = addMinutes(date, playtime).toISOString();

    const getReservedPlayersInInterval = () => {
        if (Object.keys(reservations).length > 0) {
            const reservationsTimes = Object.keys(reservations);
            const ISOStartDate = date.toISOString();
            let reservedInterval = [];
            if (
                isBefore(new Date(ISOStartDate), new Date(reservationsTimes[0])) &&
                isAfter(new Date(lastReservedTime), max(reservationsTimes.map((dateISO) => new Date(dateISO))))
            ) {
                const intervalFrom = closestTo(
                    new Date(ISOStartDate),
                    reservationsTimes.map((date) => new Date(date))
                ).toISOString();
                const intervalTo = closestTo(
                    new Date(lastReservedTime),
                    reservationsTimes.map((date) => new Date(date))
                ).toISOString();
                reservedInterval = reservationsTimes.slice(
                    reservationsTimes.indexOf(intervalFrom),
                    reservationsTimes.indexOf(intervalTo)
                );
            } else if (
                reservationsTimes.indexOf(ISOStartDate) === -1 &&
                reservationsTimes.indexOf(lastReservedTime) === -1
            ) {
                reservedInterval = [];
            } else if (
                !reservationsTimes.indexOf(lastReservedTime) === -1 &&
                reservationsTimes.indexOf(ISOStartDate) === -1
            ) {
                reservedInterval = reservationsTimes.slice(
                    0,
                    reservationsTimes.indexOf(closestTo(lastReservedTime, reservationsTimes))
                );
            } else if (
                reservationsTimes.indexOf(lastReservedTime) === -1 &&
                !reservationsTimes.indexOf(ISOStartDate) === -1
            ) {
                const intervalFrom = closestTo(
                    reservationsTimes.indexOf(ISOStartDate),
                    reservationsTimes.map((date) => parseISO(date))
                );
                const intervalTo = closestTo(
                    parseISO(lastReservedTime),
                    reservationsTimes.map((date) => parseISO(date))
                );
                reservedInterval = reservationsTimes.slice(
                    reservationsTimes.indexOf(intervalFrom.toISOString()),
                    reservationsTimes.indexOf(intervalTo.toISOString())
                );
            } else {
                const intervalFrom = closestTo(
                    new Date(ISOStartDate),
                    reservationsTimes.map((date) => new Date(date))
                ).toISOString();
                const intervalTo = closestTo(
                    new Date(lastReservedTime),
                    reservationsTimes.map((date) => new Date(date))
                ).toISOString();
                reservedInterval = reservationsTimes.slice(
                    reservationsTimes.indexOf(intervalFrom),
                    reservationsTimes.indexOf(intervalTo)
                );
            }

            let playersAmountInInterval = [];
            for (let i = 0; i < reservedInterval.length; i++) {
                playersAmountInInterval.push(reservations[reservedInterval[i]]);
            }
            const highestAmountOfPlayersInInterval = Math.max(...playersAmountInInterval);

            return highestAmountOfPlayersInInterval;
        }

        return 0;
    };

    const maxAvailablePlayers = Math.max(...new Set(spot.tariffs.map((tariff) => tariff.players)));

    const isDisabledPlayers = (players) =>
        getReservedPlayersInInterval() > 0 && players > maxAvailablePlayers - getReservedPlayersInInterval();

    const availablePlayers = playersCollection.filter((tariffPlayers) => !isDisabledPlayers(tariffPlayers));

    const formatPlayersString = (players) => {
        if (players == 1) {
            return `${players} игрок`;
        }

        if (players >= 5) {
            return `${players} игроков`;
        }

        return `${players} игрока`;
    };

    useEffect(() => {
        if (!availablePlayers.length) setPlayers(0);
        //eslint-disable-next-line
    }, [availablePlayers]);

    return (
        <div className={calcStyles.fieldGroup}>
            <label className={calcStyles.fieldLabel}>Количество игроков</label>
            <div className={calcStyles.radioFieldGroupContainer}>
                {availablePlayers.length ? (
                    availablePlayers.map((tariffPlayers, key) => (
                        <div className={calcStyles.radioFieldGroup} key={key}>
                            <input
                                type="radio"
                                value={tariffPlayers}
                                className={calcStyles.fieldInput}
                                checked={tariffPlayers === adjustedPlayers}
                                id={`playersRadio-${key}`}
                                onChange={(e) => setPlayers(e.target.value)}
                                name="players"
                            />
                            <label className={calcStyles.radioFieldLabel} htmlFor={`playersRadio-${key}`}>
                                {formatPlayersString(tariffPlayers)}
                            </label>
                        </div>
                    ))
                ) : (
                    <div className={calcStyles.textPrimary}>
                        Пожалуйста, выберите другое время, в этот интервал все VR-зоны забронированы
                    </div>
                )}
            </div>
            {playersError && <div className={calcStyles.fieldError}>Необходимо выбрать количество игроков</div>}
        </div>
    );
};

export default PlayerSelector;
