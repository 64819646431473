const detectUserSource = () => {
    const locationHref = window.location.href;
    const documentReferrer = document.referrer;

    const sourceIsInLocalStorage = localStorage.getItem("source") !== null;
    const prevPageIsMirVr = documentReferrer.includes("mir-vr.com");
    const setDocRefToLocalStorage = docRef =>
        localStorage.setItem("source", docRef);

    if (!sourceIsInLocalStorage && !prevPageIsMirVr) {
        setDocRefToLocalStorage(documentReferrer);
    } else if (sourceIsInLocalStorage && !prevPageIsMirVr) {
        setDocRefToLocalStorage(documentReferrer);
    }

    const url = localStorage.getItem("source");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");

    if (utmSource && utmMedium) return `${utmSource} / ${utmMedium}`;

    switch (true) {
        case url.includes("clck") && url.includes("yandex"):
            return "Яндекс выдача";
        case !url.includes("=") && url.includes("google"):
            return "Google выдача";
        case !url.includes("away") && url.includes("vk.com"):
            return "VK реклама";
        case url.includes("away") && url.includes("vk.com"):
            return "VK органика";
        case url.includes("maps") && url.includes("yandex"):
            return "Яндекс карты";
        case url.includes("l.instagram") && !locationHref.includes("source"):
            return "Instagram органика";
        case url.includes("instagram.com") && url.includes("="):
            return "Instagram реклама";
        case url.includes("l.facebook") && !locationHref.includes("source"):
            return "Facebook органика";
        case url.includes("link.2gis"):
            return "2gis ";

        case url.includes("tkrodeo") ||
            url.includes("trk-citymall") ||
            url.includes("okhtamall") ||
            url.includes("trkraduga") ||
            url.includes("trkcubus") ||
            url.includes("st.trkcontinent") ||
            url.includes("shkipermol") ||
            url.includes("letomall") ||
            url.includes("pearlplaza"):
            return "Сайт ТРК ";
        default:
            return url;
    }
};

export default detectUserSource;
