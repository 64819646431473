import React, { useState, useEffect, useRef } from "react";
import Firebase from "firebase";
import config from "./config";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import spotsLocal from "../newspots.json";
import "react-datepicker/dist/react-datepicker.css";
import calcStyles from "./calc.module.css";
import PlaytimeSelection from "./PlaytimeSelection";
import SpotSelector from "./SpotSelector";
import DateSelector from "./DateSelector";
import PlayersSelector from "./PlayersSelector";
import TotalPrice from "./TotalPrice";
import Button from "./atoms/Button";
import SpotContext from "./SpotContext";
import DateContext from "./DateContext";
import detectUserSource from "../utils/detectUserSource";

Firebase.initializeApp(config);

const spotsRef = Firebase.database().ref("/");

const Calc = () => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (data) => {
        if (!playtime > 0 || !players > 0) {
            return false;
        }
        data.date = format(date, "dd.MM.yyyy HH:mm");
        data.spot = "ТРК " + spot.name;
        data.players = players;
        data.trc = spot.name;
        data["action-type"] = "calc";
        data.playtime = playtime;
        data.name = name;
        data.product = "game";
        data.website = window.location.href.includes("park") ? "rodeo" : "main";
        data.price = spot.tariffs.filter(
            (tariff) => tariff.playtime == playtime && tariff.players == players && tariff.dayType == dayType
        )[0]?.price;
        data.bonusTime = spot.tariffs.filter(
            (tariff) => tariff.playtime == playtime && tariff.players == players
        )[0].bonusTime;
        data.reason = "Новый калькулятор";
        data.prevPage = document.referrer;
        data.source = detectUserSource();
        window.ewWidget.show();
        if (window.ym) {
            window.ym(68358556, "reachGoal", "calcSubmit");
        }
        localStorage.removeItem("source");
    };
    let currentDate = new Date();
    const currentDayType = currentDate.getDay() === 6 || currentDate.getDay() === 0 ? "WEEKEND" : "WEEKDAY";

    const getDefaultSpot = (spots) => {
        const spotName = document.getElementById("widget-default-spot")?.value;
        return spots.filter((spot) => spot.name === spotName)?.[0] || spots[0];
    };

    const [spots, setSpots] = useState(spotsLocal);
    const [spot, setSpot] = useState(getDefaultSpot(spots));
    const [playtime, setPlaytime] = useState(spots[0].tariffs[0].playtime);
    const [players, setPlayers] = useState(spots[0].tariffs[0].players);
    const [name, setName] = useState("");
    const [reservations, setReservations] = useState({});
    const [date, setDate] = useState(currentDate);
    const [dayType, setDayType] = useState(currentDayType);
    const [theme, setTheme] = useState("dark");

    const spotApi = { setSpot };
    const dateApi = { setDate, setDayType };

    useEffect(() => {
        fetch("https://isdayoff.ru/" + date.toISOString().slice(0, 10).replace(/-/g, ""))
            .then((res) => res.json())
            .then((data) => {
                data === 1 ? setDayType("WEEKEND") : setDayType("WEEKDAY");
            });
    }, [date]);

    useEffect(() => {
        spotsRef.on(
            "value",
            (snapshot) => {
                const fetchedSpots = snapshot.val().filter(Boolean);
                setSpots(fetchedSpots);

                if (window.location.href.includes("spot")) {
                    setSpotFromGetParams();
                } else {
                    setSpot(getDefaultSpot(fetchedSpots));
                }
            },
            (error) => {
                console.error(error);

                if (window.location.href.includes("spot")) {
                    setSpotFromGetParams();
                }
            }
        );
        //eslint-disable-next-line
    }, []);

    const setSpotFromGetParams = () => {
        const spotSlug = window.location.href.split("?")[1].split("=")[1];
        const foundedSpot = findSpotBySlug(spotSlug);
        const spotInput = document.getElementById("trc");
        setSpot(foundedSpot);
        spotInput.value = spots.indexOf(foundedSpot);
        spotInput.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const findSpotBySlug = (spotSlug) => {
        return spots.filter((spot) => spot.slug === spotSlug)?.[0];
    };

    //eslint-disable-next-line
    const spotLinkClickListener = (e) => {
        if (e.target.href?.includes("spot")) {
            e.preventDefault();
            const spotSlug = e.target.href.split("?spot=")[1];
            const foundedSpot = findSpotBySlug(spotSlug);
            const spotInput = document.getElementById("trc");
            spotApi.setSpot(foundedSpot);
            spotInput.value = spots.indexOf(foundedSpot);
            spotInput.scrollIntoView({ block: "center", behavior: "smooth" });
            if (window.ym) {
                window.ym(68358556, "reachGoal", "mapToCalcScroll");
            }
        }

        return false;
    };

    useEffect(() => {
        document.addEventListener("click", spotLinkClickListener);
        return () => {
            document.removeEventListener("click", spotLinkClickListener);
        };
    }, [spotLinkClickListener]);

    useEffect(() => {
        setPlaytime(spot.tariffs && spot.tariffs.filter((tariff) => tariff.dayType === dayType)[0]?.playtime);
        setPlayers(spot.tariffs && spot.tariffs.filter((tariff) => tariff.dayType === dayType)[0]?.players);
    }, [spot, dayType]);
    
    const bookingWidgetLinkRef = useRef(null);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmSource = urlParams.get("utm_source");
    const utmCampaign = urlParams.get("utm_campaign");

    const finalBookingUrl = spot.bookingUrl + (utmSource ? '&utm_source=' + utmSource : '') + (utmCampaign ? '&utm_campaign=' + utmCampaign : '');

    return (
        <React.Fragment>
            <div className={calcStyles.calcWrapper + " " + calcStyles[theme]}>
                <a ref={bookingWidgetLinkRef} href={finalBookingUrl} target="_blank"></a>
                <form className={calcStyles.fieldsWrapper} onSubmit={handleSubmit(onSubmit)}>
                    <SpotContext.Provider value={spotApi}>
                        <SpotSelector spots={spots} trcError={errors.trc} setSpot={setSpot} spot={spot} />
                    </SpotContext.Provider>

                    <DateContext.Provider value={dateApi}>
                        <DateSelector
                            // date={date}
                            spot={spot}
                            register={register}
                            setReservations={setReservations}
                            reservations={reservations}
                            dayType={dayType}
                            setDate={setDate}
                            dateError={errors.date}
                        />
                    </DateContext.Provider>
                    <PlaytimeSelection
                        setPlaytime={setPlaytime}
                        playtime={playtime}
                        spot={spot}
                        playtimeError={errors.playtime}
                        dayType={dayType}
                        reservations={reservations}
                        date={date}
                    />
                    <PlayersSelector
                        setPlayers={setPlayers}
                        players={players}
                        spot={spot}
                        reservations={reservations}
                        dayType={dayType}
                        date={date}
                        playtime={playtime}
                        playersError={errors.players}
                    />

                    <TotalPrice
                        totalPrice={
                            spot.tariffs.filter(
                                (tariff) =>
                                    tariff.playtime == playtime &&
                                    tariff.players == players &&
                                    tariff.dayType == dayType
                            )[0]?.price
                        }
                    />
                    <div className={calcStyles.fieldGroupButton}>
                        <Button size="xl" color="purple" type="empty" text="Записаться на игру" />
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default Calc;
