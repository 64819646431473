const getWorkingHours = (spot, dayType) => {
    const today = new Date();
    const startHours = spot[dayType.toLowerCase() + "_working_hours"]
        .split("-")[0]
        .split(":")[0];
    const startMinutes = spot[dayType.toLowerCase() + "_working_hours"]
        .split("-")[0]
        .split(":")[1];
    const startTime = new Date(
        new Date(today.setHours(startHours)).setMinutes(startMinutes)
    );
    const endHours = spot[dayType.toLowerCase() + "_working_hours"]
        .split("-")[1]
        .split(":")[0];
    const endMinutes = spot[dayType.toLowerCase() + "_working_hours"]
        .split("-")[1]
        .split(":")[1];
    const endTime = new Date(
        new Date(today.setHours(endHours)).setMinutes(endMinutes)
    );
    return [startTime, endTime];
};

export default getWorkingHours;
