import React, { useEffect } from "react";
import { differenceInMinutes, parseISO } from "date-fns";
import calcStyles from "./calc.module.css";

const PlaytimeSelection = props => {
    const {
        spot,
        playtimeError,
        setPlaytime,
        playtime,
        dayType,
        reservations,
        date
    } = props;

    const playtimeCollection = [
        ...new Set(
            spot.tariffs
                .filter(tariff => tariff.dayType === dayType)
                .map(tariff => tariff.playtime)
        )
    ];

    const adjustedPlaytime = Number(
        playtimeCollection.indexOf(Number(playtime)) < 0
            ? playtimeCollection[0]
            : playtime
    );

    const firstReservedTime = parseISO(
        Object.keys(reservations).filter(date => {
            return reservations[date] >= spot.zones;
        })[0]
    );

    const isDisabledPlaytime = playtime =>
        differenceInMinutes(firstReservedTime, date) > 0 &&
        differenceInMinutes(firstReservedTime, date) < playtime;

    const availablePlaytimes = playtimeCollection.filter(
        tariffPlaytime => !isDisabledPlaytime(tariffPlaytime)
    );

    useEffect(() => {
        if (!availablePlaytimes.includes(+playtime)) {
            setPlaytime(availablePlaytimes[0]);
        }
        if (!availablePlaytimes.length) {
            setPlaytime("");
        }
    }, [date, playtime, availablePlaytimes]);

    return (
        <div className={calcStyles.fieldGroup}>
            <label className={calcStyles.fieldLabel}>Продолжительность</label>
            <div className={calcStyles.radioFieldGroupContainer}>
                {!availablePlaytimes.length ? (
                    <div className={calcStyles.fieldWarning}>
                        На указанное время нет доступных временных интервалов.
                        Выберите другие время или локацию
                    </div>
                ) : (
                    playtimeCollection
                        .filter(
                            tariffPlaytime =>
                                !isDisabledPlaytime(tariffPlaytime)
                        )
                        .map((tariffPlaytime, key) => (
                            <div
                                className={calcStyles.radioFieldGroup}
                                key={key}
                            >
                                <input
                                    name="playtime"
                                    type="radio"
                                    className={calcStyles.fieldInput}
                                    value={tariffPlaytime}
                                    id={`playtimeRadio-${key}`}
                                    checked={
                                        tariffPlaytime === adjustedPlaytime
                                        // !isDisabledPlaytime(tariffPlaytime)
                                    }
                                    onChange={e => setPlaytime(e.target.value)}
                                    // disabled={isDisabledPlaytime(tariffPlaytime)}
                                />
                                <label
                                    className={calcStyles.radioFieldLabel}
                                    htmlFor={`playtimeRadio-${key}`}
                                >
                                    {tariffPlaytime} мин.
                                </label>
                            </div>
                        ))
                )}
            </div>
            {playtimeError && (
                <div className={calcStyles.fieldError}>
                    Необходимо выбрать время игры
                </div>
            )}
        </div>
    );
};

export default PlaytimeSelection;
