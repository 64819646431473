import React, { useContext } from "react";
import calcStyles from "./calc.module.css";
import SpotContext from "./SpotContext";

const SpotSelector = props => {
    const { spots, trcError, spot } = props;
    const spotApi = useContext(SpotContext);

    return (
        <div className={calcStyles.fieldGroup}>
            <label className={calcStyles.fieldLabel} htmlFor="trc">
                Расположение клуба
            </label>
            <select
                id="trc"
                className={calcStyles.fieldInput}
                onChange={e => {
                    spotApi.setSpot(spots[e.target.value]);
                }}
                name="trc"
            >
                {spots.map((spotItem, i) => (
                    <option key={i} value={i} disabled={!spotItem.enabled} selected={spotItem.name === spot.name}>
                        {spotItem.name}
                    </option>
                ))}
            </select>
            {trcError && "Необходимо выбрать локацию"}
        </div>
    );
};

export default React.memo(SpotSelector);
